import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

function Chat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [questions, setQuestions] = useState([]);
  const messagesEndRef = useRef(null);

  const navigate = useNavigate();

  // Check localStorage on component mount
  useEffect(() => {
    const randomToken = localStorage.getItem("randomToken");
    if (!randomToken) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendMessage = () => {
    if (input.trim()) {
      const userMessage = { text: input.trim(), sender: "user" };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      fetchResponse(input.trim());
      setInput("");
      setQuestions((prevQuestions) => [...prevQuestions, input.trim()]);
    }
  };

  const fetchResponse = async (message) => {
    try {
      const response = await fetch("https://ai.sfaplay.com/query", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question: message, otp: "1212" }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const botMessage = {
        text: formatAnswer(data.response),
        sender: "bot",
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const formatAnswer = (response) => {
    // Split response by newlines to create bullet points
    const lines = response.split("\n").filter((line) => line.trim() !== ""); // Filter out empty lines

    // Wrap each line in <li> tags to create a bullet list
    const formattedLines = lines.map((line) => `<li>${line}</li>`).join("");

    return `<div class="bg-gray-200 p-3 rounded-lg"><ul>${formattedLines}</ul></div>`;
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const handleQuestionSelect = (question) => {
    setInput(question);
    sendMessage();
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="flex flex-grow">
        {/* Sidebar */}
        <Sidebar
          questions={questions}
          onQuestionSelect={handleQuestionSelect}
        />

        {/* Chat Container */}
        <div className="flex flex-col justify-between items-center flex-grow">
          <div className="p-4 mb-4 mt-6 overflow-y-auto flex-grow w-full max-w-4xl">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`my-2 rounded-lg ${
                  message.sender === "user"
                    ? "bg-green-200 self-end"
                    : "bg-gray-200 self-start"
                }`}
                dangerouslySetInnerHTML={{ __html: message.text }}
              />
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="w-full mb-4 max-w-4xl">
            <div className="flex items-center w-full">
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type your message here..."
                className="flex-grow p-2 border border-gray-300 rounded-lg mr-2"
              />
              {/* <button
                onClick={sendMessage}
                className="p-2 bg-blue-500 text-white rounded-lg"
              >
                Send
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
