import React from "react";

const Sidebar = ({ questions, onQuestionSelect }) => {
  return (
    <div className="w-64 bg-white shadow-md p-4">
      <h2 className="text-lg font-semibold mb-4">History</h2>
      <div className="overflow-y-auto max-h-full">
        {questions.map((question, index) => (
          <div
            key={index}
            className="mb-2 cursor-pointer overflow-hidden whitespace-nowrap overflow-ellipsis"
            onClick={() => onQuestionSelect(question)}
            title={question} // Optional: Show full question on hover
          >
            {question}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
